import axios from 'api';
import Endpoints from 'endpoints';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import logger from 'shared/Logger';
import ErrorMessages from 'shared/ErrorMessages';
import { getCookie } from 'shared/utility';
import { t } from 'i18next';
import { resetCountries } from 'store/ducks/countries';

export const Types = {
  LOGIN: 'user/LOGIN',
  LOGIN_FAILED: 'user/LOGIN_FAILED',
  LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',
  LOGIN_ERROR_HANDLED: 'user/LOGIN_ERROR_HANDLED',

  SET_SESSION: 'user/SET_SESSION',

  UPDATE_SESSION: 'user/UPDATE_SESSION',
  UPDATE_SESSION_SUCCESS: 'UPDATE_SESSION_SUCCESS',
  UPDATE_SESSION_FAILED: 'user/UPDATE_SESSION_FAILED',

  UPDATE_REFRESH_TOKEN: 'user/UPDATE_REFRESH_TOKEN',
  UPDATE_REFRESH_TOKEN_SUCCESS: 'user/UPDATE_REFRESH_TOKEN_SUCCESS',
  UPDATE_REFRESH_TOKEN_FAILED: 'user/UPDATE_REFRESH_TOKEN_FAILED',

  TOKEN_REQUEST: 'user/TOKEN_REQUEST',
  TOKEN_FAILED: 'user/TOKEN_FAILED',
  TOKEN_SUCCESS: 'user/TOKEN_SUCCESS',

  RECOVERY: 'user/RECOVERY',
  RECOVERY_SUCCESS: 'user/RECOVERY_SUCCESS',
  RECOVERY_FAILED: 'user/RECOVERY_FAILED',
  RECOVERY_ERROR_HANDLED: 'user/RECOVERY_ERROR_HANDLED',
  RECOVERY_CODE_SENT_RESET: 'user/RECOVERY_CODE_SENT_RESET',

  RESET: 'user/RESET',
  RESET_FAILED: 'user/RESET_FAILED',
  RESET_SUCCESS: 'user/RESET_SUCCESS',
  RESET_ERROR_HANDLED: 'user/RESET_ERROR_HANDLED',

  GET: 'user/GET',
  GET_SUCCESS: 'user/GET_SUCCESS',
  GET_FAILED: 'user/GET_FAILED',

  UPDATE: 'user/UPDATE',
  UPDATE_SUCCESS: 'user/UPDATE_SUCCESS',
  UPDATE_FAILED: 'user/UPDATE_FAILED',

  UPDATE_PASSWORD: 'user/UPDATE_PASSWORD',
  UPDATE_PASSWORD_SUCCESS: 'user/UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILED: 'user/UPDATE_PASSWORD_FAILED',
  UPDATE_PASSWORD_RESET: 'user/UPDATE_PASSWORD_RESET',

  LOGOUT: 'user/LOGOUT',
};

const initialState = {
  accessToken: null,
  refreshToken: null,
  profile: {
    data: null,
    loading: false,
    error: null,
  },
  password: {
    loading: false,
    error: null,
    data: null,
  },
  login: {
    loading: false,
    error: null,
    alreadyLoggedAnotherDevice: false,
  },
  recovery: {
    loading: false,
    error: null,
    codeSent: false,
  },
  reset: {
    loading: false,
    error: null,
    redirect: false,
  },
  updateSession: {
    loading: false,
  },
  refreshTokenUpdate: {
    loading: false,
  },
};

export default function User(state = initialState, action) {
  switch (action.type) {
    case Types.LOGIN:
      return { ...state, login: { ...state.login, loading: true } };
    case Types.LOGIN_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: action.payload.error,
          alreadyLoggedAnotherDevice: action.payload.alreadyLoggedAnotherDevice,
        },
      };
    case Types.LOGIN_SUCCESS:
      return { ...state, ...action.payload, login: { ...state.login, loading: false } };
    case Types.LOGIN_ERROR_HANDLED:
      return { ...state, login: { ...state.login, error: null, alreadyLoggedAnotherDevice: false } };
    case Types.SET_SESSION:
      return { ...state, ...action.payload };
    case Types.UPDATE_SESSION:
      return { ...state, updateSession: { loading: true } };
    case Types.UPDATE_SESSION_SUCCESS:
      return { ...state, updateSession: { loading: false }, ...action.payload };
    case Types.UPDATE_SESSION_FAILED:
      return { ...state, updateSession: { loading: false } };
    case Types.UPDATE_REFRESH_TOKEN:
      return { ...state, refreshTokenUpdate: { loading: true } };
    case Types.UPDATE_REFRESH_TOKEN_SUCCESS:
      return { ...state, refreshTokenUpdate: { loading: false }, ...action.payload };
    case Types.UPDATE_REFRESH_TOKEN_FAILED:
      return { ...state, refreshTokenUpdate: { loading: false } };
    case Types.RECOVERY:
      return { ...state, recovery: { ...state.recovery, loading: true } };
    case Types.RECOVERY_SUCCESS:
      return { ...state, recovery: { ...state.recovery, loading: false, codeSent: true } };
    case Types.RECOVERY_FAILED:
      return {
        ...state,
        recovery: { ...state.recovery, loading: false, error: action.payload.error },
      };
    case Types.RECOVERY_ERROR_HANDLED:
      return { ...state, recovery: { ...state.recovery, error: null } };
    case Types.RECOVERY_CODE_SENT_RESET:
      return { ...state, recovery: { ...state.recovery, codeSent: false } };
    case Types.RESET:
      return { ...state, reset: { ...state.reset, loading: true } };
    case Types.RESET_FAILED:
      return { ...state, reset: { ...state.reset, loading: false, error: action.payload.error } };
    case Types.RESET_SUCCESS:
      return { ...state, ...action.payload, reset: { ...state.reset, loading: false, redirect: true } };
    case Types.RESET_ERROR_HANDLED:
      return { ...state, reset: { ...state.reset, error: null } };

    case Types.GET:
      return {
        ...state,
        profile: {
          ...initialState.profile,
          loading: true,
        },
      };

    case Types.GET_SUCCESS:
      return {
        ...state,
        profile: {
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case Types.GET_FAILED:
      return {
        ...state,
        loading: false,
        profile: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };
    case Types.UPDATE:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
        },
      };

    case Types.UPDATE_SUCCESS:
      return {
        ...state,
        profile: {
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case Types.UPDATE_FAILED:
      return {
        ...state,
        loading: false,
        profile: {
          ...state.profile,
          loading: false,
          error: action.payload.error,
        },
      };

    case Types.UPDATE_PASSWORD:
      return {
        ...state,
        password: {
          loading: true,
          data: null,
          error: null,
        },
      };

    case Types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        password: {
          loading: false,
          error: null,
          data: action.payload.data,
        },
      };
    case Types.UPDATE_PASSWORD_FAILED:
      return {
        ...state,
        password: {
          loading: false,
          error: action.payload.error,
          data: null,
        },
      };

    case Types.UPDATE_PASSWORD_RESET:
      return {
        ...state,
        password: {
          ...initialState.password,
        },
      };
    case Types.LOGOUT:
      return initialState;   
    default:
      return state;
  }
}

export const Creators = {
  setSession: (accessToken, refreshToken, data) => dispatch => {
    const { exp } = jwtDecode(accessToken);
    dispatch({ type: Types.SET_SESSION, payload: { accessToken, refreshToken, data } });
    dispatch(Creators.updateSession(refreshToken, exp));
  },
  updateSession: (refreshToken, exp) => dispatch => {
    const now = moment();
    const expiration = moment.unix(exp);
    const expirationInMilliseconds = expiration.diff(now);
    setTimeout(async () => {
      try {
        dispatch({ type: Types.UPDATE_SESSION });
        const savedData = JSON.parse(localStorage.getItem('footlink_udata'));
        if (savedData.refreshToken === refreshToken) {
          //verifica se o refreshToken já não foi alterado em outra guia
          const { data } = await axios.post(Endpoints.refreshToken, {
            refresh: refreshToken,
          });
          const savedData = JSON.parse(localStorage.getItem('footlink_udata'));
          const newData = {
            ...savedData,
            accessToken: data.access,
            refreshToken: data.refresh,
          };
          if (savedData) {
            localStorage.setItem('footlink_udata', JSON.stringify(newData));
          }
          dispatch({
            type: Types.UPDATE_SESSION_SUCCESS,
            payload: { accessToken: data.access, refreshToken: data.refresh },
          });
          const decodedJwt = jwtDecode(data.access);
          dispatch(Creators.updateSession(data.refresh, decodedJwt.exp));
        } else {
          //se já foi alterado em outra guia, então não precisamos fazer o refreshToken denovo
          const accessToken = savedData.accessToken;
          const refreshToken = savedData.refreshToken;
          dispatch({
            type: Types.UPDATE_SESSION_SUCCESS,
            payload: { accessToken, refreshToken },
          });
          const decodedJwt = jwtDecode(accessToken);
          dispatch(Creators.updateSession(refreshToken, decodedJwt.exp));
        }
      } catch (err) {
        dispatch({ type: Types.UPDATE_SESSION_FAILED });
        dispatch(Creators.logout());
      }
    }, expirationInMilliseconds);
  },
  login: (email, password, persistSession, failIfAlreadyLogged) => async dispatch => {
    dispatch({ type: Types.LOGIN });
    try {
      // token
      const { data } = await axios.post(Endpoints.token, {
        email: email.toLowerCase(),
        password,
        failIfAlreadyLogged,
      });
      const accessToken = data.access;
      const refreshToken = data.refresh;
      const { user, exp } = await Creators.loginWithBearer(accessToken, refreshToken, persistSession);
      dispatch(Creators.updateSession(refreshToken, exp));
      dispatch({
        type: Types.LOGIN_SUCCESS,
        payload: {
          accessToken,
          refreshToken,
          data: { ...user },
        },
      });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response && [400, 401].includes(response.status)) {
        if (response.data?.detail === 'user logged in another device') {
          dispatch({ type: Types.LOGIN_FAILED, payload: { error: false, alreadyLoggedAnotherDevice: true } });
        } else {
          dispatch({ type: Types.LOGIN_FAILED, payload: { error: 'erros.text49', alreadyLoggedAnotherDevice: false } });
        }
      } else if (response && response.status === 402) {
        dispatch({
          type: Types.LOGIN_FAILED,
          payload: { error: 'errorMessage.expiredAccount', alreadyLoggedAnotherDevice: false },
        });
      } else {
        dispatch({
          type: Types.LOGIN_FAILED,
          payload: { error: 'errorMessage.serviceUnavailable', alreadyLoggedAnotherDevice: false },
        });
      }
    }
  },
  loginWithBearer: async (accessToken, refreshToken, persistSession) => {
    const { user_id, exp } = jwtDecode(accessToken);
    const authorization = `Bearer ${accessToken}`;

    // user
    const userResponse = await axios.get(`${Endpoints.users}/${user_id}/`, { headers: { authorization } });
    const userData = userResponse.data;

    // organization
    let organization = null;
    const organizationData = userData.org;
    if (organizationData) {
      const team = organizationData?.team ?? {};
      const city = team?.city ?? '';
      const state = team?.state ?? '';
      const country = team?.country?.name ?? '';
      const address = [];
      if (city) address.push(city);
      if (state) address.push(state);
      if (country) address.push(country);

      organization = {
        id: userData.org,
        type: organizationData?.type,
        shortName: organizationData?.fullname ?? '',
        phones: organizationData?.phones ?? {},
        has_projects_access: organizationData?.has_projects_access ?? false,
        fullName: team?.cbf_longname1 ?? '',
        teamId: team?.id,
        img: organizationData?.emblem,
        clubImg: team?.emblem ?? '',
        city: address.join(', '),
        countryImg: team?.country?.flag ?? organizationData?.country?.flag ?? '',
      };
    }

    let has_any_permission = false;
    for (let key in userData.plan) {
      if (userData.plan[key] === true) {
        has_any_permission = true;
        break;
      }
    }

    const user = {
      id: userData.id,
      name: userData.name,
      img: userData?.profile?.photo ?? '',
      organization: organization,
      plan: {
        ...userData.plan,
        has_any_permission,
      },
      is_only_for_projects: userData.is_only_for_projects ?? false,
      is_super_user: userData.is_superuser ?? false,
    };

    localStorage.removeItem('rdf_lastUpdateNotification');
    localStorage.removeItem('rdf_excededPlanLimit');
    if (persistSession) {
      localStorage.setItem(
        'footlink_udata',
        JSON.stringify({
          accessToken,
          refreshToken,
          data: {
            ...user,
          },
        })
      );
    }

    return { user, exp };
  },
  recovery: email => async dispatch => {
    dispatch({ type: Types.RECOVERY });
    try {
      let csrftoken = getCookie('csrftoken');
      let headers = {
        'X-CSRFToken': csrftoken,
      };

      await axios.post(
        Endpoints.recoveryPassword,
        {
          email,
        },
        { headers: headers }
      );

      dispatch({ type: Types.RECOVERY_SUCCESS });
    } catch (err) {
      logger.error(err.message);
      dispatch({ type: Types.RECOVERY_FAILED, payload: { error: 'errorMessage.serviceUnavailable' } });
    }
  },
  reset: (uid, token, new_password, re_new_password) => async dispatch => {
    dispatch({ type: Types.RESET });
    try {
      let csrftoken = getCookie('csrftoken');
      let headers = {
        'X-CSRFToken': csrftoken,
      };

      await axios.post(
        Endpoints.resetPassword,
        {
          uid,
          token,
          new_password,
          re_new_password,
        },
        { headers: headers }
      );

      dispatch({ type: Types.RESET_SUCCESS });
    } catch (err) {
      logger.error(err.message);
      const { response } = err;
      if (response && response.status === 400) {
        if (response.data.new_password)
          dispatch({ type: Types.RESET_FAILED, payload: { error: response.data.new_password[0] } });
        else dispatch({ type: Types.RESET_FAILED, payload: { error: 'erros.text42' } });
      } else {
        dispatch({ type: Types.RESET_FAILED, payload: { error: ErrorMessages.serviceUnavailable } });
      }
    }
  },
  resetRecovery: () => ({ type: Types.RECOVERY_CODE_SENT_RESET }),
  handleLoginError: () => ({ type: Types.LOGIN_ERROR_HANDLED }),
  handleRecoveryError: () => ({ type: Types.RECOVERY_ERROR_HANDLED }),
  handleResetError: () => ({ type: Types.RESET_ERROR_HANDLED }),
  logout: () => async dispatch => {
    try {
      await axios.post(Endpoints.logout);
    } catch (e) {
    }

    resetCountries();
    localStorage.removeItem('footlink_udata');
    localStorage.removeItem('report');
    sessionStorage.removeItem('advanced_search');
    dispatch({ type: Types.LOGOUT });
  },
  logoutAndRedirect: () => {
    resetCountries();
    localStorage.removeItem('footlink_udata');
    localStorage.removeItem('report');
    window.location.href = '/';
  },
};

export const getUser = id => async dispatch => {
  dispatch({ type: Types.GET });

  const url = Endpoints.users;

  try {
    const { data } = await axios.get(`${url}/${id}`);

    dispatch({ type: Types.GET_SUCCESS, payload: { data } });
  } catch (err) {
    dispatch({
      type: Types.GET_FAILED,
      payload: { error: 'erros.text43' },
    });
  }
};

export const getUsers = async () => {
  try {
    const { data } = await axios.get(Endpoints.users);
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar buscar os usuários do Footlink. Por favor, tente novamente' };
  }
};

export const getAnalysts = async () => {
  try {
    const { data } = await axios.get(Endpoints.analysts);
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar buscar os analistas do Footlink. Por favor, tente novamente' };
  }
};

export const getEvaluationClubs = async () => {
  try {
    const { data } = await axios.get(Endpoints.evaluationClubs);
    return { data };
  } catch (err) {
    return { error: 'Ocorreu um erro ao tentar buscar a lista de Clubes para filtrar.' };
  }
};

export const updateUser = (id, body) => async dispatch => {
  dispatch({ type: Types.UPDATE });

  const url = Endpoints.users;

  try {
    if (body.name && body.name.length > 0) {
      const names = body.name.split(' ');
      body.first_name = names[0];
      body.last_name = '';
      for (let i = 1; i < names.length; i++) {
        body.last_name += `${names[i]} `;
      }
      body.last_name = body.last_name.trim();
    }
    const { data } = await axios.patch(`${url}/${id}`, body);

    dispatch({ type: Types.UPDATE_SUCCESS, payload: { data } });
  } catch (err) {
    dispatch({
      type: Types.UPDATE_FAILED,
      payload: { error: 'erros.text44' },
    });
  }
};

export const updateUserImage = (id, file) => async dispatch => {
  dispatch({ type: Types.UPDATE });

  const url = Endpoints.users;

  try {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    // const updatedData = parseOrganizationUpdate(flatData);

    const formData = new FormData();
    formData.append('photo', file);
    const { data } = await axios.patch(`${url}/${id}`, formData, config);
    const savedData = JSON.parse(localStorage.getItem('footlink_udata'));
    if (savedData?.data) {
      savedData.data.img = data.profile.photo;
      localStorage.setItem('footlink_udata', JSON.stringify(savedData));
    }

    dispatch({ type: Types.UPDATE_SUCCESS, payload: { data } });
    return data;
  } catch (err) {
    dispatch({
      type: Types.UPDATE_FAILED,
      payload: { error: 'erros.text44' },
    });
  }
};

export const passwordUpdate = body => async dispatch => {
  const url = '/auth/users/set_password/';
  dispatch({ type: Types.UPDATE_PASSWORD });
  try {
    await axios.post(`${url}`, body);
    dispatch({ type: Types.UPDATE_PASSWORD_SUCCESS, payload: { data: t('erros.text45') } });
  } catch (err) {
    if (err.response?.data) {
      dispatch({
        type: Types.UPDATE_PASSWORD_FAILED,
        payload: {
          error:
            err.response.data.current_password || err.response.data.new_password || err.response.data.re_new_password,
        },
      });
    } else {
      dispatch({
        type: Types.UPDATE_PASSWORD_FAILED,
        payload: { error: 'erros.text46' },
      });
    }
  }
};

export const passwordUpdateReset = () => async dispatch => {
  dispatch({ type: Types.UPDATE_PASSWORD_RESET });
};
